import React from "react";
import { graphql } from "gatsby";
import { Link } from "gatsby";
// import { Location } from '@reach/router';
import Img from "gatsby-image";

import Layout from "../components/layout";
import Title from "../components/title";
import Inview from "../components/inview";

export default function Logisticsequipment({ data }) {
  const pageData = {
    titleClass: "logistics",
    title: "IoT事業部",
    discription: "",
    image: 29,
  };
  const seodata = {
    title: "IoT Solution Division アヴィエラン株式会社　IoT事業部",
    seo: {
      description: "世界規模でカゴ車・コンテナ・パレット等の物流資材の位置を可視化し、業務効率化、コスト削減を支援します。",
      image: {
        url: data.datoCmsImage.topimage[5].url,
      },
    },
    slug: "ec-solution-division",
  };
  return (
    <Layout pagestyle={pageData} seo={seodata}>
      <Title titlestyle={pageData} />
      <div className="pankz">
        <Link to="/">トップ</Link>
        <p>IoT事業部</p>
      </div>
      <div className="l_page_contents">
        <div className="box1">
          <Inview c_name="image">
            <Img fluid={data.datoCmsImage.topimage[28].news} alt="アヴィエラン株式会社　IoT事業部" />
            <Inview c_name="title" tag="p">
              IoTとは
              <span>What is IoT?</span>
            </Inview>
          </Inview>
          <div className="text">
            <Inview tag="p" c_name="comment">
              IoT「Internet of Things」とは、「モノのインターネット」のこと。従来インターネットに接続されていなかった様々なモノ（センサー機器、駆動装置（アクチュエーター）、住宅・建物、車、家電製品、電子機器など）が、ネットワークを通じてサーバーやクラウドサービスに接続され、相互に情報交換をする仕組みです。
            </Inview>
            <div className="freedial">
              <p>お問合せはこちら</p>
              <Img fluid={data.datoCmsImage.topimage[26].free} alt="フリーダイヤル" />
              <span className="pctel">0120-947-576</span>
              <a className="sptel" href="tel:0120947576">
                0120-947-576
              </a>
            </div>
          </div>
        </div>
        <div className="box1 ">
          <Inview c_name="image">
            <Img fluid={data.datoCmsImage.topimage[7].news} alt="アヴィエラン株式会社　IoT事業部" />
            <Inview c_name="title" tag="p">
              世界物流のIoTインフラを構築
              <span>Build IoT infrastructure for global logistics </span>
            </Inview>
          </Inview>
          <div className="text">
            <Inview tag="p" c_name="comment">
              AVIELANはLPWAを利用した世界規模(グローバルで75か国)でのカゴ車・コンテナ・パレット等の物流資材の位置を可視化し、業務効率化、コスト削減を支援します。
            </Inview>
          </div>
        </div>

        <div className="box1">
          <Inview c_name="image">
            <Img fluid={data.datoCmsImage.topimage[30].news} alt="アヴィエラン株式会社　IoT事業部" />
            <Inview c_name="title" tag="p">
              物流ソリューション
              <span>Logistics solution </span>
            </Inview>
          </Inview>
          <div className="text">
            <Inview tag="p" c_name="comment">
              物流資材に通信デバイスをセットし、最新の所在や移動履歴を可視化しすることで、物流資材の紛失や滞留、偏在を抑止します。これにより追加購入コスト、管理コスト、輸送コストの削減に貢献します。
            </Inview>
            <Link to="/press/">
              <span>事例はこちら</span>
            </Link>
          </div>
        </div>

        <div className="box1">
          <Inview c_name="image">
            <Img fluid={data.datoCmsImage.topimage[29].news} alt="アヴィエラン株式会社　IoT事業部" />
            <Inview c_name="title" tag="p">
              LPWAとは
              <span>What is LPWA?</span>
            </Inview>
          </Inview>
          <div className="text">
            <Inview tag="p" c_name="comment">
              LPWA（Low Power Wide Area-network、LPWANとも称される）は、その名のとおり「省電力かつ長距離での無線通信が可能」という特長をもった通信技術の総称です。 世界のIoTデバイスが数百億と普及するIoT（Internet of Things）社会の本格的な到来に向け、従来よりも低消費電力、広いカバーエリア、低コストを可能にするLPWAネットワークが期待されています。2022年には50億台ものデバイスがLPWAネットワークに接続されるとも言われています。
            </Inview>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query {
    datoCmsImage {
      topimage {
        url
        news: fluid(maxWidth: 1200, forceBlurhash: false, imgixParams: { auto: "compress", fit: "crop", w: "600", h: "600" }) {
          ...GatsbyDatoCmsFluid
        }
        free: fluid(maxWidth: 1200, forceBlurhash: false, imgixParams: { auto: "compress", fit: "crop", w: "248", h: "142" }) {
          ...GatsbyDatoCmsFluid
        }
      }
    }
  }
`;
